.component {
  & > .content {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-100);
    }
  }

  & > .circles {
    width: 100%;
    height: 100%;
  }
}

.content {
  @media (--viewport-lg) {
    padding: 0 var(--size-100);
  }
}

.circles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--size-20);
  position: relative;
  z-index: 0;
  line-height: 1.1;
  text-align: center;
  user-select: none;

  @media (--viewport-md) {
    gap: initial;
    grid-template-columns: 1fr minmax(100px, 25%) 1fr;
  }

  & > .circleLayout,
  & > .initialCircle {
    @media (--viewport-md) {
      grid-area: 2 / 2 / 3 / 2;
    }
  }

  & > .initialCircle {
    z-index: 1;
  }
}

.initialCircle {
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: 50%;
  text-align: center;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-base-500);
  display: none;
  pointer-events: none;

  @media (--viewport-md) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (--viewport-lg) {
    font-size: var(--font-size-24);
  }
}

.componentCircle {
  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }

  & > .inner {
    position: absolute;
    inset: 0;

    @media (--viewport-md) {
      width: 95%;
      height: 95%;
    }
  }

  &:hover {
    & > .inner {
      &.hasDescription {
        transform: rotateY(180deg);
      }
    }
  }
}

.inner {
  position: relative;
  transition: transform 0.5s;
  transition-delay: 0.15s;
  transform-style: preserve-3d;

  & > .side {
    width: 100%;
    position: absolute;
    height: 100%;
  }
}

.side {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-base-500);

  @media (--viewport-lg) {
    font-size: var(--font-size-24);
  }
}

.back {
  font-size: 12px;
  transform: rotateY(180deg);

  @media (--viewport-lg) {
    font-size: var(--font-size-18);
  }

  & > * {
    max-width: 85%;
  }
}
