.component {
  color: var(--color-white);
  font-size: var(--font-size-14);
  position: relative;
  background-color: var(--color-black);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0;
    transition: opacity 200ms ease-out;
    pointer-events: none;
  }

  &.isLoading {
    &::after {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.contentContainer {
  padding-top: var(--size-20);
  padding-bottom: var(--size-20);

  @media (--viewport-md) {
    align-items: center;
    display: flex;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-10);

    @media (--viewport-md) {
      margin-bottom: 0;
      margin-right: var(--size-50);
    }
  }
}

.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: var(--size-10);
  }
}
