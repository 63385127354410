.componentPage {
  & > .heroLayout {
    margin-top: var(--size-100);
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-top: 0;
      margin-bottom: var(--size-80);
    }
  }
}

.componentTextBlock {
  padding: var(--size-100) 0;

  @media (--viewport-lg) {
    padding: 0;
  }
}
