.component {
  & > .title {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-70);
    }
  }

  & > .content {
    @media (--viewport-lg) {
      margin-left: var(--size-100);
    }
  }
}

.title {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: 0.75;
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-60-100);

  @media (--viewport-md) {
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}
