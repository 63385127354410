.componentPage {
  background: var(--color-gray-10);

  & > * {
    height: 100%;
  }
}

.content {
  padding: var(--size-30) 0;

  @media (--viewport-md) {
    padding: var(--size-100) 0;
  }
}
