.component {
  background-color: var(--color-green);
  padding: var(--size-100) var(--size-20);

  @media (--viewport-md) {
    padding: var(--size-200) var(--size-20);
  }

  & > .layout {
    max-width: var(--container-xl);
    margin-inline: auto;
  }
}

.layout {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 128px));
  gap: var(--size-90) var(--size-30);
  justify-content: space-around;

  @media (--viewport-lg) {
    justify-content: space-between;
    grid-template-columns: repeat(4, minmax(100px, auto));
  }

  @media (--viewport-lg) {
    grid-template-columns: repeat(6, minmax(100px, auto));
  }
}

.item {
  & > .logo {
    width: 100%;
    height: 100%;
  }
}
