.casesContainer {
  display: flex;
  justify-content: center;
  padding-inline: var(--size-20);

  @media (--viewport-md) {
    padding-inline: var(--size-40);
  }

  & > * {
    width: 100%;

    @media (--viewport-xxl) {
      max-width: 75vw;
    }
  }
}

.componentDesktop {
  column-gap: var(--size-80);
  columns: 2;
}

.componentCaseLandscape {
  aspect-ratio: 7 / 5;

  @media (--viewport-sm) {
    aspect-ratio: 5 / 4;
  }

  @media (--viewport-xl) {
    aspect-ratio: 7 / 5;
  }
}

.componentCasePortrait {
  aspect-ratio: 7 / 10;
}

.componentCaseBase {
  display: block;
  break-inside: avoid;
  overflow: hidden;
  position: relative;

  & > .imageContainer {
    position: absolute;
    inset: 0;
  }

  & > .content {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.content {
  padding-block: var(--size-20);
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  font-weight: var(--font-weight-base-400);
}

.client {
  font-size: var(--font-size-14-18);
}

.title {
  color: var(--color-gray-40);
  font-size: var(--font-size-12-16);
}

.componentCaseImage {
  transition: opacity 300ms linear, scale 500ms ease-out;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }

  &:hover {
    scale: 1.03;
    opacity: 0.8;
  }
}

.introText {
  font-size: var(--font-size-22);
  padding-top: var(--size-100);
  @media (--viewport-md) { font-size: var(--font-size-24); }
  @media (--viewport-lg) { font-size: var(--font-size-28); }
}
