.component {
  position: relative;
  display: none;

  @media (--viewport-lg) {
    display: flex;
    align-items: center;
  }

  & > .filterLine {
    width: var(--size-30);
    height: var(--size-2);
    margin-inline: var(--size-10);
  }
}

.filtersLabel {
  white-space: nowrap;
}

.filterLine {
  display: inline-block;
  background-color: var(--color-white);
}

.filtersWrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  & > .filtersToggle {
    z-index: 2;
  }

  & > .expandedFilters {
    left: var(--size-10);
    position: absolute;
    height: 100%;
  }
}

.filtersToggle {
  position: relative;
}

.expandedFilters {
  display: flex;
  align-items: center;
  padding-left: calc(100% + var(--size-10));
  white-space: nowrap;

  & > .button {
    margin-right: var(--size-15);
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.activeFilter {
  opacity: 1;
}

.button {
  padding-block: var(--size-50);
  opacity: 0.5;
}
