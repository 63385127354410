.componentBase {
  display: inline-block;
  outline: none;
  transition: opacity 200ms;

  &:hover { opacity: 0.7; }
}

.componentGhost {
  border: solid 2px currentColor;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-18);
  line-height: 1;
  padding: 14px var(--size-40);
  text-align: center;
}

.componentLink {
  text-decoration: underline;
}

.buttonInner {
  position: relative;
}
