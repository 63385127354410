.component {
  z-index: 0;
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }

  & > .backgroundLayout {
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.componentBackground {
  display: block;
  background-color: var(--color-blue);

  & > .yellowBackground {
    width: 100%;
    height: 100%;
  }
}

.yellowBackground {
  display: block;
  background-color: var(--color-yellow);
  transform-origin: bottom;
}

.wrapper {
  & > .container {
    max-height: 100svh;
  }
}

.container {
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }
}

.componentCustomers,
.componentAwards {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  padding: var(--size-60) 0 var(--size-80);

  @media (--viewport-md) {
    gap: var(--size-80);
    padding: var(--size-180) 0;
  }
}

.componentLogoList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--size-10) var(--size-5);
  justify-items: center;
  align-content: center;

  @media (--viewport-lg) {
    grid-template-columns: repeat(5, 1fr);
  }
}
