.component {
  & > .heading {
    margin-bottom: var(--size-15);
  }

  & > .subheading {
    margin-bottom: var(--size-10);
  }

  & > .paragraph:not(:last-child),
  & > .paragraphLg:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  & > .list:not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentMark {
  background: var(--color-blue);
  color: var(--color-black);
  padding: 4px;
}

.green { background: var(--color-green); }
.yellow { background: var(--color-yellow); }
.orange { background: var(--color-orange); }
.blue { background: var(--color-blue); }
.pink { background: var(--color-pink); }

.heading,
.subheading {
  padding-bottom: 0 !important;
  font-weight: var(--font-weight-base-500);
}

.heading {
  font-size: var(--font-size-35);
}

.subheading {
  font-size: var(--font-size-28);
}

.strong {
  font-weight: var(--font-weight-base-500);
}

.listOrdered {
  list-style-type: decimal;
  list-style-position: outside;
  padding-inline: var(--size-25);
}

.listUnordered {
  list-style-type: disc;
  list-style-position: outside;
  padding-inline: var(--size-25);
}

.link {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.paragraphLg {
  font-size: var(--font-size-20-24);
}
