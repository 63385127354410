.component {
  --color-bg: var(--color-black);
  --color-text: var(--color-white);

  background-color: var(--color-bg);
  color: var(--color-text);

  & > .container {
    max-width: var(--container-xl);
    margin: 0 auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-50);
  padding: var(--size-100) var(--size-20);

  @media (--viewport-md) {
    padding: var(--size-180) var(--size-20);
  }

  & > .text {
    @media (--viewport-md) {
      max-width: 900px;
    }
  }
}

.links {
  display: flex;
  gap: var(--size-20);
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.text {
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-35);
  line-height: var(--line-height-compact-md);

  @media (--viewport-md) {
    font-size: var(--font-size-45);
  }
}
