.component {
  & > .slide {
    width: 100%;
    height: 120lvh;
    top: 0;

    &:last-child {
      height: 100lvh;
    }
  }
}

.slide {
  position: sticky;

  & > .slideLayout {
    width: 100%;
    height: 120lvh;
    top: 0;

    &:last-child {
      height: 100lvh;
    }
  }
}

.componentSlide {
  color: var(--color-white);

  & > .layout {
    width: 100%;
    height: 100%;
  }
}

.layout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 0;

  & > .content {
    height: 100%;
    width: 100%;
    max-width: var(--container-xl);
    margin: 0 auto;

    @media (--viewport-md) {
      height: auto;
      margin: 0 auto 100px auto;
      position: static;
    }
  }

  & > .media {
    position: absolute;
    inset: 0;
    z-index: -1;
  }
}

.content {
  padding: var(--size-150) var(--size-20) var(--size-70) var(--size-20);
  display: grid;
  grid-template-areas:
    "description description"
    "title title"
    "button indicator";
  grid-template-rows: min-content auto min-content;
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-20) var(--size-20) var(--size-20) var(--size-20);
    justify-content: space-between;
    grid-template-columns: minmax(450px, 850px) auto;
    row-gap: var(--size-30);
    grid-template-areas:
      "description ."
      "title indicator";
  }

  & > .linkTitle {
    grid-area: title;
    position: static;
  }

  & > .description {
    grid-area: description;
    max-width: var(--container-xsm);
    margin-bottom: var(--size-10);
  }

  & > .indicator {
    grid-area: indicator;
  }

  & > .linkMobile {
    grid-area: button;
  }
}

.title {
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-35);
  font-size: var(--font-size-35-60);
  line-height: 1.2;
}

.description {
  font-weight: var(--font-weight-base-300);
  font-size: var(--font-size-22);
  line-height: 1;
}

.indicator {
  display: flex;
  justify-content: flex-end;

  @media (--viewport-md) {
    align-items: flex-end;
  }
}

.indicatorInner {
  display: flex;
  align-items: center;
  gap: var(--size-20);
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    gap: var(--size-40);
    font-size: var(--font-size-45);
  }
}

.linkTitle {
  position: relative;

  @media (--viewport-md) {
    cursor: url('/images/cursor.svg'), auto;
  }

  &::after {
    @media (--viewport-md) {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
    }
  }
}

.linkMobile {
 text-decoration: none;

  @media (--viewport-md) {
    display: none;
  }
}

.linkInner {
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: var(--radius-60);
  padding: var(--size-10) var(--size-25);
  text-align: center;
}

.iconLayout {
  --icon-size: 20px;

  & > * {
    width: var(--icon-size);

    @media (--viewport-md) {
      --icon-size: 30px;
    }
  }
}

.media {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::after {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  & > .inner {
    width: 100%;
    height: 100%;
  }
}

.inner {
  position: relative;
  z-index: 0;

  & > .imageLayout {
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  & > .videoLayout {
    height: 100%;
    width: 100%;
  }
}

.componentImage {
  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.componentVideo {
  & > .videoLayout {
    height: 100%;
  }
}
