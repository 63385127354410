.componentClients {
  z-index: 0;
  position: relative;

  & > .imageWrapperClients {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
  }
}

.componentIntro {
  position: relative;
  white-space: nowrap;

  & > .imageWrapperIntro {
    position: absolute;
    bottom: 0;
    right: 50%;
  }
}

.imageWrapperClients,
.imageWrapperIntro {
  pointer-events: none;
  overflow: visible;
}

.imageWrapperClients { transform: translate(-50%, -50%); }

.componentAnimatedImageBase {
  will-change: transform;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
}
