.component {
  background-color: var(--color-blue);
  padding: var(--size-50) var(--size-20);
  row-gap: var(--size-100);

  @media (--viewport-md) {
    padding: var(--size-100) var(--size-20);
  }

  & > .layout {
    max-width: var(--container-lg);
    margin-inline: auto;
  }
}

.layout {
  & > .content {
    margin-bottom: var(--size-100);

    @media (--viewport-md) {
      margin-bottom: var(--size-150);
    }
  }
}

.content {
  & > .titleLayout {
    margin-bottom: var(--size-30);
  }

  & > .text {
    &:not(:last-child) {
      margin-bottom: var(--size-20);
    }
  }
}

.title {
  font-weight: var(--font-weight-base-500);
  letter-spacing: var(--letter-spacing-compact);
  line-height: 1;
  font-size: var(--font-size-40-80);
}

.text {
  font-size: var(--font-size-18);

  @media (--viewport-lg) {
    font-size: var(--font-size-24);
  }
}
