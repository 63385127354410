._rootBase {
  height: var(--size-30);
}

.componentBase {
  display: flex;
  padding: var(--size-5) var(--size-10);
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-12);
  gap: var(--size-10);
  transition: transform 0.2s ease-out;
  border-radius: var(--size-70);
  color: var(--color-black);
}

.componentTheme {
  overflow: hidden;
}
