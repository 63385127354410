.componentBase {
  flex-wrap: wrap;
  display: flex;
}

.componentTagBase {
  color: var(--color-white);
  background: var(--color-black);
  user-select: none;
}

.componentSm {
  gap: 8px;
}

.componentMd {
  gap: var(--size-15);

  @media (--viewport-md) {
    gap: var(--size-20);
  }
}

.tagSm {
  font-size: var(--font-size-12);
  padding: 2px 6px;
  border-radius: 4px;
}

.tagMd {
  padding: 7px var(--size-20);
  border-radius: var(--size-10);
  font-size: var(--font-size-14);

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}
