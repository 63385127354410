.component {
  background-color: var(--color-white);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewport-lg) {
    overflow-y: hidden;
    flex-direction: row;
  }

  & > .imageAndTitle {
    order: -1;

    @media (--viewport-lg) {
      width: 40%;
      max-width: var(--container-md);
    }
  }

  & > .content {
    @media (--viewport-lg) {
      flex-grow: 1;
    }
  }

  & > .buttonWrapper {
    position: fixed;
    right: 0;
    bottom: var(--size-20);

    @media (--viewport-lg) {
      bottom: auto;
      top: 0;
    }
  }
}

.imageAndTitle {
  position: relative;
  z-index: 0;

  & > .marqueeLayout {
    width: 100vw;

    @media (--viewport-lg) {
      position: absolute;
      width: 100vh;
      left: 0;
      z-index: 1;
      top: 100%;
    }
  }

  & > .coverImage {
    width: 100%;

    @media (--viewport-lg) {
      height: 100%;
    }
  }
}

.coverImage {
  position: relative;
  z-index: 0;
  background-color: var(--color-gray-10);
  aspect-ratio: 1;

  @media (--viewport-md) {
    aspect-ratio: 16 / 9;
  }

  @media (--viewport-lg) {
    aspect-ratio: auto;
  }

  & > .imageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    @media (--viewport-lg) {
      position: static;
      inset: unset;
    }
  }

  & > .videoLayout {
    position: absolute;
    inset: 0;
  }
}

.videoLayout {
  & > * {
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: var(--size-30) 0 var(--size-50) 0;

  @media (--viewport-lg) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: var(--size-50) var(--size-70) var(--size-80) var(--size-10);
  }

  & > .text {
    @media (--viewport-lg) {
      max-width: 700px;
    }
  }
}

.title {
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-35);
  line-height: 1;
  padding: 0 var(--size-20);

  @media (--viewport-md) {
    padding: 0 var(--size-40);
  }

  @media (--viewport-lg) {
    font-size: var(--font-size-45);
  }
}

.buttonWrapper {
  padding: var(--size-40) var(--size-25);

  @media (--viewport-lg) {
    padding: var(--size-40) var(--size-25);
  }

  & > .button {
    width: var(--size-70);
    min-height: var(--size-70);
  }
}

.button {
  border: 1px solid;
  border-radius: 50%;
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-18);
  background-color: var(--color-white);
}

.tags {
  padding: 0 var(--size-20);

  @media (--viewport-md) {
    padding: 0 var(--size-40);
  }
}

.componentMarquee {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  background-color: var(--color-white);
  gap: var(--size-20);

  & > .marqueeContentLayout {
    flex: 0 0 auto;
  }

  @media (--viewport-lg) {
    transform-origin: top left;
    transform: rotate(270deg);
  }
}

.componentMarqueeContent {
  display: flex;
  flex-wrap: nowrap;
  animation: marquee 40s linear infinite;
  gap: var(--size-20);

  & > .marqueeItem {
    flex: 0 0 auto;

    @media (--viewport-md) {
      margin-top: -12px;
    }
  }
}

.marqueeItem {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  text-transform: uppercase;
  font-size: var(--font-size-80);
  line-height: 1;
  letter-spacing: var(--letter-spacing-compact-xl);

  @media (--viewport-md) {
    font-size: var(--font-size-150);
  }
}

@keyframes marquee {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
