.component {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .layout {
    width: 100%;
    margin-bottom: var(--size-60);
  }
}

.layout {
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding: unset;
  }

  & > .headingWrapper {
    z-index: -1;
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      width: 100%;
      margin-bottom: calc(-1 * var(--size-50));
    }
  }

  & > .carouselWrapper {
    width: 100%;
  }

  & > .cursorWrapper {
    position: absolute;
    left: 0;
    width: var(--size-100);
    height: var(--size-100);
    z-index: 1;
  }
}

.headingWrapper {
  overflow: hidden;
  padding-inline: var(--size-20);

  @media (--viewport-md) {
    padding: unset;
  }

  & > .heading {
    @media (--viewport-md) {
      margin-left: 250px;
    }
  }
}

.heading {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-80);
  font-size: var(--font-size-80-300);
  padding-bottom: 0 !important;
  user-select: none;
  cursor: none;

  @media (--viewport-md) {
    letter-spacing: var(--letter-spacing-compact-xl);
    white-space: nowrap;
  }
}

.carouselWrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 0 var(--size-50) 0 var(--size-20);

  @media (--viewport-md) {
    cursor: none;
    padding-inline: var(--size-100);
  }

  @media (--viewport-xl) {
    padding-inline: var(--size-150);
  }

  & > .carousel {
    width: 100%;
  }
}

.carousel {
  --min-height-carousel-item: 350px;

  position: relative;
  display: flex;
  overflow: visible;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;

  & > .carouselItem {
    min-height: var(--min-height-carousel-item);

    @media (--viewport-md) {
      --min-height-carousel-item: 400px;
    }

    @media (--viewport-lg) {
      --min-height-carousel-item: 450px;
    }
  }
}

.carouselItem {
  & > .cardLayout {
    height: 100%;
  }
}

.cursorWrapper {
  pointer-events: none;

  & > .cursorLayout {
    height: 100%;
  }
}

.componentCursor {
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  border-radius: 50%;
  display: none;
  user-select: none;

  @media (--viewport-md) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.componentEmployee {
  & > .coverImage {
    margin-bottom: var(--size-10);
  }

  & > .content {
    margin-right: var(--size-10);
  }
}

.content {
  line-height: 1.3;

  & > .name {
    margin-bottom: var(--size-5);

    @media (--viewport-md) {
      margin-bottom: var(--size-5);
    }
  }
}

.name {
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-22);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}

.roleAndNote {
  font-size: var(--font-size-16);

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}

.coverImage {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((7/ 5) * 100%);
  }

  & > .imageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
