.articles {
  display: grid;
  gap: var(--size-50);

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    grid-auto-rows: var(--size-120);
  }

  @media (--viewport-lg) {
    gap: var(--size-70);
  }
}

.article {
  & > * {
    height: 100%;
  }
}

.componentCard {
  display: flex;
  flex-direction: column;

  & > .coverImage {
    margin-bottom: var(--size-15);
    flex-grow: 1;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--size-30);

  & > .circleButton {
    flex-shrink: 0;
  }
}

.titleAndInfo {
  & > .title {
    margin-bottom: 5px;
  }
}

.title {
  font-weight: var(--font-weight-base-500);
}

.info {
  font-weight: var(--font-weight-base-400);
}

.coverImage {
  overflow: hidden;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: cover;
}

.title {
  font-size: var(--font-size-24);
  line-height: 1.3;

  @media (--viewport-md) {
    font-size: var(--font-size-35);
  }
}
