.cursor,
.cursorPosition { pointer-events: none; }
.cursor { transform: translate(-50%, -50%); }

.component {
  cursor: none;
  position: relative;

  & > .cursorPosition {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
