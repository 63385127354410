:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-10: #f8f8f8;
  --color-gray-40: #8d8d8d;
  --color-gray-70: #4d4d4d;
  --color-gray-80: #1e1e1e;
  --color-gray-90: #1c1c1c;
  --color-shocking-pink: #ed1ee6;

  --color-pink: #ff4dc1;
  --color-blue: #1dcbff;
  --color-yellow: #ffe419;
  --color-green: #a8e536;
  --color-orange: #ff6817;
  --color-soft-orange: #ffc3a2;
  --color-soft-blue: #a5eaff;
  --color-soft-green: #dcf5af;
}

:export {
  --color-pink: #ff4dc1;
  --color-blue: #1dcbff;
  --color-yellow: #ffe419;
  --color-green: #a8e536;
  --color-orange: #ff6817;
}
