.componentBase {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  &.left > *:first-child {
    order: 0;
  }

  &.right > *:first-child {
    order: 1;
  }
}

.componentVideo,
.componentImage {
  & > .mediaTypeElement {
    width: 100%;
    height: 100%;
  }

  & > .caption {
    margin-top: var(--size-10);
  }
}

.mediaTypeElement {
  aspect-ratio: 1 / 1;
  padding: var(--size-20);
  @media (--viewport-md) { padding: unset; }
}

.splitBlockContent {
  display: flex;
  align-items: center;
  line-height: 1.45;
  font-weight: var(--font-weight-base-400);
  letter-spacing: var(--letter-spacing-compact);
  font-size: var(--font-size-18);
  @media (--viewport-sm) { font-size: var(--font-size-20); }
  @media (--viewport-md) { font-size: var(--font-size-24); }
  @media (--viewport-lg) { font-size: var(--font-size-28); }

  & > * {
    margin: var(--size-20);
    @media (--viewport-md) { margin: var(--size-40); }
    @media (--viewport-lg) { margin: var(--size-60); }
    @media (--viewport-xl) { margin: var(--size-80); }
  }
}

.caption {
  font-size: var(--font-size-14);
}
