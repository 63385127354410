.componentWithRatioBase {
  overflow: hidden;
  position: relative;

  & > .forceRatio {
    width: 100%;
    height: 0;
  }

  & > .videoWithRatioLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.componentWithoutRatioBase {
  & > .videoWithoutRatioLayout {
    width: 100%;
  }
}

.videoWithRatioLayout {
  object-fit: cover;
}

.tommy {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;

  & > * {
    width: 93%;
    height: 93%;
  }
}

.componentTommy {
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  /*
    Safari doesn't like the above, this fixes it
    https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
  */
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  /* force square ratio */
  height: 0;
  padding-bottom: 100%;

  & > .tommy {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.iconPlay {
  display: inline-flex;
  position: relative;
}

.followCursorCursor {
  & > .iconPlay {
    left: 2px;
  }
}
