.componentCampaign {
  display: block;
  border-top: 10px solid var(--color-gray-70);
  padding-top: var(--size-30);
  padding-bottom: var(--size-30);
  position: relative;
  transition: color 200ms;

  &:hover,
  &:focus {
    color: var(--color-gray-70);
  }

  &::before {
    content: '';
    width: 100%;
    height: 10px;
    background: var(--color-blue);
    position: absolute;
    top: -10px;
    left: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover::before,
  &:focus::before {
    transform: scaleX(1);
  }
}

.componentSubscribe {
  display: inline-block;
  padding: 10px 20px;
  background-image: var(--gradient-purple);
  background-size: 300% 100%;
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  font-size: 24px;
  text-transform: uppercase;
  color: white;
}
