.componentArchive {
  padding: 80px 0;

  & > *:not(:last-child) { margin-bottom: 20px; }
}

.componentCampaigns {
  list-style: none;
  padding: 0;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.componentErrorMessage { padding: 20px 0; }

.actions {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.componentPagination {
  display: flex;
  justify-content: space-between;
}

.prev { padding: 20px 20px 20px 0; }
.next { padding: 20px 0 20px 20px; }

.prev,
.next {
  transition: color 200ms;

  &:hover,
  &:focus {
    color: var(--color-gray-70);
  }
}

.componentDescription {
  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}
