.layout {
  & > :not(:last-child) {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-80);
    }
  }
}

.header {
  & > .heading {
    margin-bottom: var(--size-20);
  }

  & > .introduction {
    @media (--viewport-lg) {
      max-width: 80%;
    }
  }
}

.heading {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-80);
  font-size: var(--font-size-80-300);
  padding-bottom: 0 !important;

  @media (--viewport-md) {
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}

.introduction {
  font-weight: var(--font-weight-base-500);

  @media (--viewport-md) {
    font-size: var(--font-size-35);
    line-height: 1.3;
  }
}

.linkButton {
  display: flex;
  justify-content: center;
}

.componentCard {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: var(--size-40);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-lg) {
    gap: var(--size-80);
  }

  & > .coverImage {
    order: -1;
  }
}

.title {
  font-size: var(--font-size-40);
  line-height: 1.1;
  font-weight: var(--font-weight-base-500);

  @media (--viewport-xl) {
    font-size: var(--font-size-60);
  }
}

.contributors {
  font-weight: var(--font-weight-base-500);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > .title {
    margin-bottom: var(--size-20);
  }
}

.coverImage {
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-bottom: calc((1 / 1) * 100%);
  }

  & > .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
