/* https://webaim.org/techniques/css/invisiblecontent/ */
.component {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
  height: 1px !important;
  width: 1px !important;

  /* stylelint-disable kaliber/no-layout-related-props-in-root */
  margin: -1px;
  position: absolute;
  /* stylelint-enable kaliber/no-layout-related-props-in-root */
}
