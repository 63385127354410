.component {
  white-space: nowrap;
  overflow: hidden;

  & > .list {
    width: max-content;
  }
}

.list {
  display: flex;
  animation: scroll var(--animation-speed, 40s) forwards linear infinite;
  will-change: transform;

  & > .item {
    margin-inline: 5vw;
  }
}

@keyframes scroll {
  to {
    transform: translate(-50%);
  }
}

.item,
.heading {
  --scale: 0.75;

  --width: 100vw;

  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  text-transform: uppercase;
  line-height: 1;
  font-size: calc(var(--width) / (10 * 0.5) * var(--scale, 1));

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::before {
    margin-bottom: -0.18em;
  }

  &::after {
    margin-top: -0.08em;
  }
}
