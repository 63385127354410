.componentBase {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    opacity: 0.1;
    transition: opacity 200ms;
    will-change: opacity;
  }

  &.isActive {
    border-color: var(--color-black);
  }
}

.componentClipped {
  height: 100%;
  width: 100%;
}

.component {
  border-radius: 50%;
  border: 2px solid var(--color-black);
  color: var(--color-white);
  fill: white;
  background-color: black;
  height: var(--size-70) !important;
  width: var(--size-70) !important;
  transition: border-color 200ms;
}
