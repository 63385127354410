.component {
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: var(--size-50);
  padding: var(--size-50) var(--size-20);

  @media (--viewport-lg) {
    padding: var(--size-150) var(--size-20);
    flex-direction: row;
    justify-content: center;
  }
  @media (--viewport-xl) {
    gap: var(--size-100);
  }

  & > .content {
    max-width: var(--container-md);
    margin-bottom: var(--size-50);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }

  & > .video {
    width: 100%;

    @media (--viewport-lg) {
      order: -1;
      max-width: 700px;
    }
  }
}

.heading {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-60);

  @media (--viewport-md) {
    font-size: var(--font-size-80);
    font-size: var(--font-size-80-300);
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);
  align-items: flex-start;
  padding-top: var(--size-50);

  & > .text:not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.text {
  font-size: var(--font-size-18);

  @media (--viewport-xl) {
    font-size: var(--font-size-24);
  }
}
