.component {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: center;

  @media (--viewport-sm) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  @media (--viewport-lg) {
    gap: 20px 70px;
  }

  @media (--viewport-xl) {
    grid-template-columns: repeat(5, 1fr);
  }

  & > * {
    min-width: 0;
  }
}
