:root {
  --breakpoint-sm: 500px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 960px;
  --breakpoint-xl: 1170px;
  --breakpoint-xxl: 1600px;
  --breakpoint-xxxl: 2500px;
}

:export {
  breakpoint-sm: 500px;
  breakpoint-md: 768px;
  breakpoint-lg: 960px;
  breakpoint-xl: 1170px;
  breakpoint-xxl: 1600px;
  viewportMd: screen and (min-width: 768px);
  viewportLg: screen and (min-width: 960px);
  viewportXl: screen and (min-width: 1170px);
  viewportXxl: screen and (min-width: 1600px);
  viewportXxxl: screen and (min-width: 2500px);
}

@custom-media --viewport-sm screen and (min-width: 500px);
@custom-media --viewport-md screen and (min-width: 768px);
@custom-media --viewport-lg screen and (min-width: 960px);
@custom-media --viewport-xl screen and (min-width: 1170px);
@custom-media --viewport-xxl screen and (min-width: 1600px);
@custom-media --viewport-xxxl screen and (min-width: 2500px);
