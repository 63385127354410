.component {
  display: flex;
  justify-content: center;
  padding-block: var(--size-80);

  & > .layout {
    width: 100%;
    max-width: var(--container-xxxl);
  }
}

.layout {
  --container-quote: 900px;

  display: grid;
  grid-template: repeat(4, auto) /  var(--size-20) 1fr 1fr var(--size-80) var(--size-70);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    grid-template: minmax(auto, var(--size-120)) auto 1fr auto minmax(auto, var(--size-120));
  }

  @media (--viewport-xl) {
    grid-template: repeat(4, auto) /  minmax(min-content, 300px) auto 1fr auto minmax(min-content, 300px);
  }

  & > .quote {
    width: 100%;
    max-width: --container-quote;
    grid-area: 2 / 2 / 4 / 5;
  }

  & > .mark {
    z-index: -1;
  }

  & > .markStart {
    grid-area: 1 / 1 / 3 / 3;
  }

  & > .markEnd {
    grid-area: 3 / 4 / 4 / 6;
    justify-self: end;
  }
}

.quote {
  & > .blockquote {
    margin-bottom: var(--size-40);
  }
}

.blockquote {
  font-size: var(--font-size-35-60);
  font-weight: var(--font-weight-base-500);
  line-height: var(--line-height-compact-md);
}

.source {
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-22);
  display: flex;
  gap: var(--size-25);
  align-items: center;

  & > .image {
    width: var(--size-60);
    height: var(--size-60);
  }
}

.image {
  border-radius: 50%;
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.imageLayout {
  object-fit: cover;
}

.mark {
  color: var(--color-yellow);

  & > .markLayout {
    width: 125px;

    @media (--viewport-md) { width: 300px; }
    @media (--viewport-lg) { width: 350px; }
    @media (--viewport-xl) { width: 400px; }
  }
}
