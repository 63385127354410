.componentPage {
  & > .modalLayout {
    position: fixed;
    width: 100%;
    min-height: 100%;
    height: 100svh;
    top: 0;
    z-index: 2;
  }
}

.items {
  display: grid;
  padding-top: var(--size-100);
  gap: var(--size-50);

  @media (--viewport-md) {
    gap: var(--size-100);
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-xl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  & > * {
    height: 100%;

    @media (--viewport-md) {
      height: initial;
    }
  }
}

.componentCard {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  cursor: none !important;

  & > * {
    cursor: none !important;
  }

  & > .coverImage {
    width: 100%;
    order: -1;
    margin-bottom: var(--size-10);
  }

  & > .button {
    position: static;
    z-index: 2;
  }

  & > .cursorWrapper {
    position: absolute;
    left: 0;
    width: var(--size-70);
    height: var(--size-70);
    z-index: 3;
  }
}

.cursorWrapper {
  pointer-events: none;
}

.coverImage {
  position: relative;
  background-color: var(--color-gray-10);

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }

  & > .imageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.button {
  text-align: left;
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-24);
  position: relative;

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}
