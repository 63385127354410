@value _height: 30px;
@value _height-md: 40px;
@value _count-size: 20px;
@value _count-size-md: 26px;

.component {
  /* space for count */
  padding: 6px 6px 0 0;
  position: relative;

  & > .count {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.count {
  background-color: var(--color-pink);
  width: _count-size !important;
  height: _count-size !important;
  line-height: _count-size;
  color: white;
  text-align: center;
  border-radius: 50%;
  font-size: var(--font-size-14);
  pointer-events: none;

  @media (--viewport-md) {
    width: _count-size-md !important;
    height: _count-size-md !important;
    line-height: _count-size-md;
    font-size: var(--font-size-18);
  }
}

.inner {
  background-color: white;
  padding-left: 2em;
  padding-right: 2em;
  height: _height !important;
  line-height: _height;
  border-radius: calc(_height / 2);
  font-size: 14px;

  @media (--viewport-md) {
    font-size: 18px;
    height: _height-md !important;
    line-height: _height-md;
    border-radius: calc(_height-md / 2);
  }
}

.text { white-space: nowrap; }
