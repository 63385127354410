.component {
  background-color: black;
  color: var(--color-white);
  padding: var(--size-30) 0;
  font-size: var(--font-size-16);

  @media (--viewport-md) {
    font-size: var(--font-size-18);
    padding: var(--size-70) 0;
  }
}

.inner {
  & > :not(:last-child) { margin-bottom: var(--size-30); }
}

.sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > .section,
  & > .linksAndLogos {
    margin-bottom: var(--size-30);
    width: 50%;
    @media (--viewport-md) { width: 25%; }
  }
}

.section {
  & > :not(:last-child) { margin-bottom: var(--size-20); }
}

.componentLink {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-base-300);
}

.link {
  font-weight: 300;
}

.linksAndLogos {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);
  padding-bottom: var(--size-40);

  @media (--viewport-md) {
    padding-bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .logos {
    order: -1;

    @media (--viewport-md) {
      order: 0;
    }
  }
}

.legalLinks {
  font-size: var(--font-size-14);
  line-height: 2.5;

  @media (--viewport-md) {
    line-height: 1.7;
  }

  & > :not(:last-child) {
    margin-right: var(--size-20);
    @media (--viewport-md) { margin-right: var(--size-30); }
  }
}

.logos {
  display: flex;
  gap: var(--size-15);
  align-items: flex-end;
  flex-wrap: wrap;

  & > .logo {
    height: var(--size-40);
  }

  & > .logoIso {
    height: var(--size-50);
  }
}

.logo {
  color: var(--color-white);
  fill: var(--color-white);
}
