.component {
  display: flex;
  flex-direction: column;
}

.layout {
  display: grid;
  column-gap: var(--size-20);
  row-gap: var(--size-40);
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (--viewport-md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (--viewport-lg) {
    column-gap: 25px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.componentEmployee {
  & > .coverImage {
    margin-bottom: var(--size-10);
  }
}

.content {
  line-height: 1.1;

  & > .name {
    margin-bottom: 5px;
  }
}

.name {
  font-weight: var(--font-weight-base-500);

  @media (--viewport-md) {
    font-size: 22px;
  }
}

.roleAndNote {
  font-size: 12px;

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}

.coverImage {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((7/ 5) * 100%);
  }

  & > .imageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
