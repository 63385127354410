.imageContainer {
  position: relative;

  & > .image {
    width: 100%;
    height: 100%;
  }

  & > .labelsOverlay {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.labelsOverlay {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--size-10);
  padding: var(--size-20);
}
