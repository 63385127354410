.component {
  padding: 75px 0;
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-100) 0 var(--size-50) 0;
  }

  & > .headingWrapper {
    @media (--viewport-md) {
      width: 100%;
      margin-bottom: calc(-1 * var(--size-60));
    }
  }
}

.headingWrapper {
  overflow: hidden;

  & > .heading {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }
}

.heading {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-80);
  font-size: var(--font-size-80-300);
  padding: 0 var(--size-20) !important;
  user-select: none;

  @media (--viewport-md) {
    white-space: nowrap;
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}

.componentMobile,
.componentDesktop {
  font-family: var(--font-family-base);
  padding: 0 var(--size-40);

  & > .layout {
    width: 100%;
    max-width: var(--container-lg);
    margin: 0 auto;

    @media (min-width: 1800px) {
      max-width: 1600px;
    }
  }
}

.layout {
  display: grid;
  gap: var(--size-30);
  position: relative;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.componentImage {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((2 / 3) * 100%);
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  & > .coverImageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.itemMobile {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-rows: minmax(100px, 230px) minmax(1fr, auto);

  & > .content {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
    position: static;
  }

  & > .imageLayout {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    z-index: -1;
  }
}

.itemDesktop {
  background-color: var(--color-black);
  transition: transform 0.2s ease;
  position: relative;

  &.isActiveLayout {
    transform: scale(1.025);
  }

  &.notActiveLayout {
    transform: scale(0.975);
  }

  & > .textDesktop {
    position: absolute;
    inset: 0;
    margin: var(--size-25);

    @media (--viewport-lg) {
      margin: var(--size-50);
    }
  }
}

.imageLayout {
  transition: opacity 0.2s ease;

  &.notActiveLayout {
    opacity: 0.4;
  }
}

.content {
  position: relative;
  padding: 25px 25px 0 25px;

  & > .buttonLayout {
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 85px;
  }

  & > .linkLayout {
    position: static;
  }
}

.linkLayout {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.text {
  line-height: 1.1;

  & > .client {
    margin-bottom: var(--size-10);
  }
}

.textDesktop {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--size-15);
  position: relative;

  & > .title {
    margin-left: -3px;
  }

  & > .buttonLayout {
    align-self: flex-end;
    width: 50px !important;
    height: 50px !important;
    position: absolute;
    right: 0;
    top: 10%;
  }
}

.client {
  font-size: 16px;

  @media (--viewport-lg) {
    font-size: var(--font-size-24);
  }
}

.title {
  font-size: var(--font-size-28);
  font-weight: var(--font-weight-base-400);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
    line-height: var(--line-height-compact);
  }

  @media (--viewport-xl) {
    font-size: var(--font-size-45);
  }
}
