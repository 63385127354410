.componentCases {
  display: flex;
  justify-content: center;
  padding-inline: var(--size-20);

  @media (--viewport-md) {
    padding-inline: var(--size-40);
  }

  & > * {
    width: 100%;

    @media (--viewport-xxl) {
      max-width: 75vw;
    }
  }
}

.buttonCases {
  display: flex;
  justify-content: center;
  padding-top: var(--size-40);
}
