.component {
  display: grid;
  position: relative;
  z-index: 0;
  color: var(--color-white);
  background-color: var(--color-black);
  padding-block: var(--size-40) var(--size-90);

  @media (--viewport-lg) {
    padding-block: var(--size-120);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(min-content, 325px) auto 1fr;
    column-gap: var(--size-10);
  }

  & > .titleComponent {
    margin-left: calc(-1 * var(--size-5));
    z-index: -1;

    @media (--viewport-lg) {
      margin-left: calc(-1 * var(--size-10));
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }
  }

  & > .contact {
    @media (--viewport-lg) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }

  & > .imageContainer {
    z-index: -1;

    @media (--viewport-lg) {
      justify-self: end;
      grid-column: 1 / 2;
      grid-row: 1 / 4;
    }
  }

  & > .faq {
    margin-top: var(--size-60);

    @media (--viewport-lg) {
      margin-top: var(--size-150);
      grid-column: 1 / -1;
      grid-row: 3 / 4;
    }
  }
}

.imageContainer {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }

  & > .image {
    width: 100%;
    max-width: 800px;
    margin-top: var(--size-70);
  }
}

.contact {
  padding: var(--size-20) var(--size-25);

  & > .contactText {
    max-width: 600px;
    margin-bottom: var(--size-30);
  }
}

.contactText {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-base-500);
  line-height: var(--line-height-compact-md);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }

  @media (--viewport-xl) {
    font-size: var(--font-size-45);
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--size-20);

  @media (--viewport-md) {
    align-items: start;
  }

  @media (--viewport-xl) {
    flex-direction: row;
    align-items: center;
    gap: var(--size-40);
  }

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.title {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: 0.75;
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;

  @media (--viewport-md) {
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}

.titleComponent {
  font-size: var(--font-size-80-200);
}

.titleFaq {
  font-size: var(--font-size-60);

  @media (--viewport-lg) {
    font-size: var(--font-size-100);
  }
}

.faq {
  padding-inline: var(--size-25);

  & > .faqInner {
    width: 100%;
    max-width: 1100px;
    margin-inline: auto;
  }
}

.faqInner {
  & > .titleFaq {
    margin-bottom: var(--size-20);

    @media (--viewport-lg) {
      margin-bottom: var(--size-80);
    }
  }
}

.componentFaqItem {
  padding-block: var(--size-30);
  border-bottom: 1px solid;

  @media (--viewport-md) {
    padding-block: var(--size-40);
  }

  &:first-of-type {
    border-top: 1px solid;
  }

  & > .questionHeader {
    width: 100%;
  }

  & > .answer {
    @media (--viewport-lg) {
      width: calc(100% - var(--size-100));
    }
  }
}

.questionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: var(--size-20);
  position: relative;
  z-index: 0;

  & > .buttonWrapper {
    position: static;
  }
}

.buttonWrapper {
  position: relative;
  z-index: 0;

  & > .buttonLayout {
    position: static;
  }
}

.buttonLayout {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    width: 53px;
    height: 53px;
    left: unset;

    @media (--viewport-md) {
      width: 73px;
      height: 73px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.answer {
  overflow: hidden;
}

.answerInner {
  padding-top: var(--size-30);
}

.icon {
  aspect-ratio: 1;
  transform: rotate(90deg);
  transition: transform var(--motion-duration-400);

  &.isExpanded {
    transform: rotate(-90deg);
  }
}
