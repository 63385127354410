.component {
  display: flex;
  justify-content: center;
  padding-inline: var(--size-20);

  @media (--viewport-md) { padding-inline: var(--size-40); }

  & > * { width: 100%; }

  & > .sm { max-width: var(--container-sm); }
  & > .md { max-width: var(--container-md); }
  & > .lg { max-width: var(--container-lg); }
  & > .xl { max-width: var(--container-xl); }
  & > .xxl { max-width: var(--container-xxl); }
}
