.casesContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  align-items: center;
  padding-inline: var(--size-20);

  @media (--viewport-md) {
    padding-inline: var(--size-40);
  }

  & > * {
    width: 100%;

    @media (--viewport-xxl) {
      max-width: 75vw;
    }
  }

  & > .filtersLayout {
    margin: var(--size-20);
  }
}
