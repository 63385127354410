.componentBase {
  /* for sticky footer in IE11 */
  display: flex;
  & > .inner { width: 100%; }
}

.inner {
  /* for sticky footer */
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;

  /* new stacking context */
  position: relative;
  z-index: 0;

  & > .main {
    /* for sticky footer */
    flex-grow: 1;
  }

  & > .header,
  & > .cookieBar {
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  & > .header { top: 0; }
  & > .cookieBar { bottom: 0; }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-20) var(--size-20) 0;

  @media (--viewport-md) { padding: var(--size-50) var(--size-50) 0; }

  pointer-events: none;
  & > * { pointer-events: auto; }

  &.withJobsLink {
    flex-wrap: wrap;

    & > .jobsLinkLayout {
      /* force to go below rest of header */
      order: 1;
      margin-right: 100%;

      @media (--viewport-md) {
        /* align visual center button of button with other elements */
        margin-bottom: 10px;

        /* reset */
        margin-left: auto;
        margin-right: 0;
        order: 0;
      }
    }

    & > .menuToggle { @media (--viewport-md) { margin-left: 30px; } }
  }
}

.menuToggle {
  &.isHidden {
    display: none;
  }
}

.isHidden {
  display: none;
}
