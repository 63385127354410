.component {
  padding: 0 var(--size-20);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding: 0;
  }

  & > .headingWrapper {
    z-index: -1;
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      width: 100%;
      margin-bottom: calc(-1 * var(--size-50));
    }
  }

  & > .carouselWrapper {
    width: 100%;
  }

  & > .cursorWrapper {
    position: absolute;
    left: 0;
    width: var(--size-100);
    height: var(--size-100);
    z-index: 1;
  }
}

.headingWrapper {
  overflow: hidden;

  & > .heading {
    @media (--viewport-md) {
      margin-left: 200px;
    }
  }
}

.heading {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-80);
  font-size: var(--font-size-80-300);
  padding-bottom: 0 !important;
  user-select: none;
  cursor: none;

  @media (--viewport-md) {
    letter-spacing: var(--letter-spacing-compact-xl);
    white-space: nowrap;
  }
}

.carouselWrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 0 50px 0 0;

  @media (--viewport-md) {
    cursor: none;
    padding: 0 var(--size-100);
  }

  @media (--viewport-xl) {
    padding: 0 150px;
  }

  & > .carousel {
    width: 100%;
  }
}

.carousel {
  position: relative;
  display: flex;
  overflow: visible;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0;

  & > .carouselItem {
    min-height: 350px;

    @media (--viewport-md) {
      min-height: 400px;
    }

    @media (--viewport-lg) {
      min-height: 450px;
    }
  }
}

.carouselItem {
  & > .cardLayout {
    height: 100%;
  }
}

.componentCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 0;

  & > .icon {
    order: -1;
    width: var(--size-100);
    margin-left: 24px;

    @media (--viewport-md) {
      width: var(--size-100);
    }
  }
}

.content {
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  & > .title {
    margin-bottom: var(--size-10);
  }
}

.title {
  font-weight: var(--font-weight-base-500);
  font-size: var(--font-size-24);
  line-height: var(--line-height-compact);

  @media (--viewport-lg) {
    font-size: var(--font-size-28);
  }
}

.description {
  font-size: var(--font-size-16);
  line-height: 1.4;

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}

.cursorWrapper {
  pointer-events: none;

  & > .cursorLayout {
    height: 100%;
  }
}

.componentCursor {
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  border-radius: 50%;
  display: none;
  user-select: none;

  @media (--viewport-md) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
