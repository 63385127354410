.component {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewport-md) {
    flex-direction: row-reverse;
    align-items: flex-start;
  }

  @media (--viewport-lg) { align-items: center; }

  & > .video {
    width: 100%;
    @media (--viewport-sm) { width: 60%; }
  }

  & > .content,
  & > .video {
    @media (--viewport-md) { flex: 1 1 50%; }
    @media (--viewport-lg) { flex: 1 1 40%; }
  }

  & > *:not(:last-child) {
    margin-bottom: 50px;

    @media (--viewport-md) {
      margin-bottom: 0;
      margin-left: 50px; /* row-reverse */
    }
  }
}

.video { color: var(--color-gray-10); }

.content { @media (--viewport-lg) { padding-left: 100px; } }
