.heading {
  padding-top: 100px;
  padding-bottom: 40px;

  @media (--viewport-md) {
    padding-top: 180px;
    padding-bottom: 60px;
  }
}

.introduction {
  padding-top: var(--size-30);
  font-weight: var(--font-weight-base-500);
  line-height: 1.4;

  @media (--viewport-md) {
    font-weight: var(--font-weight-base-400);
    padding-top: var(--size-40);
    font-size: var(--font-size-28);
  }

  @media (--viewport-lg) {
    font-size: var(--font-size-35);
  }
}
