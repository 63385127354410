@value _imageWidth: 480px;
@value _imageWidthMd: 870px;
@value _imageHeight: 620px;
@value _imageHeightMd: 725px;
@value _titleHeight: 485px;

:exports {
  imageWidth: _imageWidth;
  imageHeight: _imageHeight;
  imageWidthMd: _imageWidthMd;
  imageHeightMd: _imageHeightMd;
}

.component {
  display: flex;
  justify-content: center;
  background-color: black;
  position: relative;
  z-index: 0;

  & > .borderBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    height: 120px;
    z-index: -1;
  }
}

.borderBottom { background-color: white; }

.layout {
  max-width: calc(var(--container-md) + 40px + 40px) !important;
  display: grid;
  grid-template-columns: 1fr 4fr 10%;
  grid-template-rows: _titleHeight minmax(0, calc(_imageHeight - _titleHeight)) minmax(0, auto);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1.5fr 20%;
    grid-template-rows: _titleHeight  minmax(0, calc(_imageHeightMd - _titleHeight)) minmax(0, auto);
  }

  @media (--viewport-xl) { grid-template-columns: 2fr 1fr 50%; }

  & > .titleLayout {
    grid-column: 1/3;
    grid-row: 1;
    align-self: end;
  }

  & > .imageLayout {
    grid-column: 2/4;
    grid-row: 1/3;
  }

  & > .contentLayout {
    grid-column: 1/3;
    grid-row: 2/4;
  }

  & > .spacer {
    grid-column: 3;
    grid-row: 3;
  }
}

.imageLayout {
  & > .componentImage {
    width: 100%;
    height: 100%;
  }
}

.componentImage {
  object-fit: cover;
  object-position: center;
}

.componentTitle {
  padding: 40px;
  @media (--viewport-md) { padding: 60px 20px; }
  @media (--viewport-xl) { padding-left: 40px; }
}

.title {
  color: white;
  font-family: var(--font-family-condensed);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-compact-xl);
  line-height: var(--line-height-compact);
  font-size: 60px;

  @media (--viewport-md) { font-size: 80px; }
}

.contentLayout {
  & > * { height: 100%; }
}

.componentContent {
  background-color: white;
  padding: 20px;

  @media (--viewport-lg) {
    padding: 40px 20px;
    font-size: 24px;
  }

  @media (--viewport-xl) { padding: 40px; }
}

.spacer { background-color: white; }
