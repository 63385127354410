@font-face {
  font-family: 'JohnDoe';
  src: url('/fonts/JohnDoe-Bold.woff2') format('woff2'), url('/fonts/JohnDoe-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.page {
  & > * {
    height: 100vh;
  }
}

.componentPlaceholder {
  position: relative;
  z-index: 0;

  & > .space {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.space {
  object-fit: cover;
}

.componentPlayer {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & > .paper {
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  & > .container {
    position: relative;
    z-index: 3;
  }
}

.background {
  object-fit: cover;
  object-position: center;
}

.container {
  position: relative;

  & > .arrow {
    position: absolute;
    right: -20%;
    bottom: 15%;

    @media (--viewport-md) {
      right: -10%;
      bottom: 15%;
    }
  }

  & > .player {
    height: 350px;
    width: 350px;

    @media (--viewport-md) {
      height: 450px;
      width: 450px;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > .button {
    height: 250px;
    width: 250px;

    @media (--viewport-md) {
      height: 400px;
      width: 400px;
    }
  }

  & > .title {
    margin-top: 10px;
  }
}

.title {
  font-family: 'JohnDoe';
  transform: rotate(-10deg);
  font-size: 16px;

  @media (--viewport-md) {
    font-size: var(--font-size-35);
  }
}

.player {
  position: relative;
  z-index: 0;
  transform: rotate(-2deg);

  & > .videoFrame {
    position: absolute;
    top: 8%;
    left: 5%;
    width: 90%;
    height: 80%;
  }

  & > .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  & > .number,
  & > .againTitle {
    position: absolute;
    bottom: -12%;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  & > .againTitle {
    bottom: -8%;
  }

  & > .againButton {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 2;
  }
}

.frame {
  object-fit: contain;
  object-position: top center;
}

.number {
  text-align: center;
  font-size: var(--font-size-35);
  font-family: 'JohnDoe';
}

.againButton {
  transform: translateX(-50%);
}

.againImage {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.againTitle {
  text-align: center;
  font-size: var(--font-size-20);
  font-family: 'JohnDoe';

  @media (--viewport-md) {
    font-size: var(--font-size-35);
  }
}

.video {
  object-fit: cover;
}
