.component {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--size-20);

  @media (--viewport-md) {
    gap: var(--size-40);
  }

  @media (--viewport-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (--viewport-xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.componentCard {
  background-color: var(--color-gray-10);
  border-radius: var(--size-10);
  overflow: hidden;
  aspect-ratio: 1;
  display: grid;
  position: relative;
  z-index: 0;
  transition: scale var(--motion-duration-400);

  &:hover {
    @media (--viewport-md) {
      scale: 1.025;
    }
  }

  & > .content,
  & > .coverImage {
    grid-area: 1 / 1;
  }

  & > .coverImage {
    z-index: -1;
  }
}

.content {
  opacity: 0;
  transition: opacity var(--motion-duration-400);

  &:hover {
    @media (--viewport-md) {
      background-color: var(--bg-color);
      opacity: 1;
    }
  }

  & > .contentInner {
    width: 100%;
    height: 100%;
  }
}

.contentInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--size-20);
  opacity: 0;
  transition: opacity var(--motion-duration-100);
  position: relative;
  z-index: 0;

  &:hover {
    opacity: 1;
  }

  @media (--viewport-md) {
    padding: var(--size-25);
  }

  & > .link {
    position: static;
  }
}

.coverImage {
  position: relative;
  z-index: 0;
  aspect-ratio: 1 / 1;

  &::after {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 76%, rgba(0, 0, 0, 0.1) 100%);
    z-index: 2;
  }

  & > .imageLayout,
  & > .placeholder {
    width: 100%;
    height: 100%;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.05;
  user-select: none;

  & > .tommyLayout {
    width: var(--size-150);
    height: var(--size-150);

    @media (--viewport-md) {
      width: var(--size-200);
      height: var(--size-200);
    }
  }
}

.link {
  position: relative;
  appearance: none;
  padding: 0;
  text-align: left;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.title {
  font-weight: var(--font-weight-base-500);
  line-height: 1.2;

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}
