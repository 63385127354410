.componentContent {
  & > .container {
    width: 100%;
    min-height: 100vh;
  }
}

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (--viewport-md) { position: relative; }

  & > .content {
    flex-grow: 1;

    @media (--viewport-md) {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    @media (--viewport-lg) {
      bottom: 60px;
      right: 40px;
    }
  }
}

.content {
  background-color: white;
  padding: 40px 20px;

  @media (--viewport-md) {
    background-color: transparent;
    color: white;
  }
}

.componentTitlePart {
  font-weight: var(--font-weight-condensed-800);
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
}
