.componentBase {
  @media (--viewport-lg) { padding: var(--size-200) 0; }

  & > :not(:last-child) {
    margin-bottom: var(--size-30);
    @media (--viewport-md) { margin-bottom: var(--size-60); }
  }
}

.introText {
  font-size: var(--font-size-22);
  @media (--viewport-md) { font-size: var(--font-size-24); }
  @media (--viewport-lg) { font-size: var(--font-size-28); }

  & > *:not(:last-child) {
    margin-bottom: var(--size-40);
  }
}

.componentLink {
  background: linear-gradient(to right, var(--color-blue) 100%, transparent 0);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 2px;
  padding-bottom: 2px;
}
