.componentGallery,
.componentCaseVideo,
.componentCarousel {
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
}

.componentMobileVideo {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);

  & > * {
    @media (--viewport-md) {
      width: calc(50% - 50px);
      max-height: 80vh;
    }
  }

  @media (--viewport-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.isReversed {
    flex-direction: row-reverse;
  }
}

.componentLargeImage {
  display: flex;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  & > .image {
    width: 100%;
    max-width: 100%;
    height: auto;
    flex: 0 0 auto;
  }

  &.sm > .image {
    max-width: var(--container-sm);
  }

  &.md > .image {
    max-width: var(--container-md);
  }

  &.lg > .image {
    max-width: var(--container-lg);
  }

  &.xl > .image {
    max-width: var(--container-xl);
  }
}

.componentTextBlock {
  & > .headingLayout {
    margin-bottom: var(--size-20);
  }

  & > p:not(:last-child) { margin-bottom: var(--size-30); }
}

.componentCodeBlock {
  border-radius: var(--size-10);
  overflow: hidden;
}

.componentTextWithMedia {
  & > * {
    max-width: var(--container-lg);
    margin: 0 auto;
  }
}

.videoContainer {
  & > * {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
}
