.componentBase {
  display: inline-block;
  outline: none;
  transition: opacity 200ms;

  &:hover { opacity: 0.7; }
}

.component,
.componentDark {
  position: relative;
  width: 53px;
  height: 53px;
  background: transparent;
  color: var(--color-black);
  z-index: 0;

  @media (--viewport-md) {
    width: 73px !important;
    height: 73px !important;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: var(--color-white);
    transition: transform 0.2s ease-out;
    z-index: -1;
  }

  &:hover {
    opacity: 1;
    &::before {
      transform: scale(1.05);
    }
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentDark {
  color: var(--color-white);

  &::before {
    background: var(--color-black);
  }
}
