.component {
  & > *:not(:last-child) {
    margin-bottom: 30px;
    @media (--viewport-md) { margin-bottom: 50px; }
  }
}

.caption {
  display: flex;
  justify-content: center;

  & > * {
    max-width: var(--container-md);
    width: 100%;
    min-height: 120px;
    @media (--viewport-md) { min-height: 90px; }
    @media (--viewport-lg) { min-height: 60px; }
  }
}

.componentCaption {
  position: relative;

  & > .captionAnimation {
    position: absolute;
    top: 0;
  }
}

.captionAnimation {
  will-change: transform, opacity;
}
