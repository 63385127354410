.component {
  display: flex;
  justify-content: center;
  padding-inline: var(--size-20);
  position: relative;
  z-index: 0;

  @media (--viewport-md) { padding-inline: var(--size-40); }

  & > .formWrapper {
    max-width: var(--container-lg);
    margin-top: var(--size-200);
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  & > .imageWrapper {
    margin-left: auto;
    z-index: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: left;
  z-index: 0;

  & > .form {
    max-width: 600px;
    margin-bottom: 200px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--size-50);
  position: relative;

  & > .buttonLayout {
    max-width: var(--size-90);
  }

  & > .inputWrapper {
    width: 100%;
    margin-bottom: 4px;
  }
}

.inputWrapper {
  --label-scale: 1;
  --label-offset: -50%;

  position: relative;
  display: flex;
  border-bottom: var(--size-2) solid var(--color-black);
  box-sizing: border-box;

  &:focus-within,
  &:not(:has(:placeholder-shown)) {
    --label-scale: 0.75;
    --label-offset: calc(-100% - 10px);
  }

  & > .input {
    height: var(--size-50);
  }

  & > .label {
    position: absolute;
    left: 0;
    top: 50%;
  }

  & > .errorMessage {
    margin-bottom: var(--size-10);
    position: static;
  }
}

.input {
  font-size: var(--font-size-16);
  color: var(--color-black);
  padding: var(--size-20) 0 var(--size-10) 0;
  line-height: 1.5;
  position: relative;

  &::placeholder {
    color: transparent;
  }
}

.label {
  transform-origin: left center;
  transform: scale(var(--label-scale)) translateY(var(--label-offset));
  transition: transform 300ms ease;
}

.inputError {
  border-bottom-color: var(--color-yellow);
}

.errorMessage {
  color: var(--color-black);
  background-color: var(--color-yellow);
  font-size: var(--font-size-12);
  white-space: nowrap;
  border-radius: var(--size-20);
  padding: var(--size-10) var(--size-15);
  display: flex;
  align-items: center;
  gap: var(--size-10);
}

.imageWrapper {
  & > .imageLayout {
    width: 65%;
    height: 65%;

    @media (--viewport-sm) { width: 75%; }
    @media (--viewport-md) { width: 85%; }
  }
}
